<template>
  <div>
    <b-row>
      <b-col class="text-right">
        <b-button 
          to="mhd-orders"
          size="sm"
          variant="primary"
          class="mb-1"
        >
          <feather-icon icon="LayersIcon" />
          {{$t('Orders History')}}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- <b-col md="3">
        <b-link to="/mhd-store/mhd-products/1">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <b-img src="https://cdn.mhd.sa/asset/images/logo.png" height="80" />
              </p>
              <h5 class="text-center">
                {{$t('Tasees Plan')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col> -->
      <b-col md="3">
        <b-link to="/mhd-store/mhd-products/2">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <b-img src="https://cdn.mhd.sa/asset/images/logo.png" height="80" />
              </p>
              <h5 class="text-center">
                {{$t('Tawasw Plan')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
      <!-- <b-col md="3">
        <b-link to="/mhd-store/mhd-products/6">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <b-img src="https://cdn.mhd.sa/asset/images/providers/sms.png" height="80" />
              </p>
              <h5 class="text-center">
                {{$t('Recharge SMS balance')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col> -->
      <!-- <b-col md="3">
        <b-link to="/mhd-store/mhd-products/3">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <b-img src="https://cdn.mhd.sa/asset/images/providers/Aramex-logo.png" height="80" />
              </p>
              <h5 class="text-center">
                {{$t('Recharge Aramex balance')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col> -->
      <!-- <b-col md="3">
        <b-link to="/mhd-store/mhd-products/4">
          <b-card no-body>
            <b-card-body>
              <p class="text-center">
                <b-img src="https://cdn.mhd.sa/asset/images/providers/DHL-logo.png" height="80" />
              </p>
              <h5 class="text-center">
                {{$t('Recharge DHL balance')}}
              </h5>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'Mhd-Store',
  data() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>
